import React from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
let axios = require('axios');
let sessionstorage = require('sessionstorage');

class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    let wesdata=JSON.parse(sessionstorage.getItem('wesdata'));
    if(!wesdata){
      navigate("/login")
    }
  }

  

  render() {
    let wesdata=JSON.parse(sessionstorage.getItem('wesdata'));
    if(wesdata){
      console.log(wesdata);
      return (
        <Layout>
          <section className="section" style={{paddingTop:"1.25em"}}>
            <div className="container">
              
              <div class="tabs" style={{marginBottom:".75em"}}>
                <ul>
                  <li class="is-active"><a><span>Dashboard</span></a></li>
                  <li><a><span>Products</span></a></li>
                  <li><a><span>Rules</span></a></li>
                  <li><a><span>Categories</span></a></li>
                  <li><a><span>Lists</span></a></li>
                </ul>
              </div>
                
              
              <div class="columns">
                <div class="column is-half">
                  <div style={{border:"1px solid #ccc",borderRadius:".4em .4em 0 0"}}>
                    <div style={{color:"#444",backgroundColor:"#ddd",padding:".1em",paddingLeft:".5em",borderRadius:".3em .3em 0 0",borderBottom:"1px solid #ccc"}}>
                      My Rules
                    </div>
                    <div style={{backgroundColor:"#fff",padding:".35em",paddingLeft:".5em"}}>
                      Rules go here
                    </div>
                  </div>
                </div>
                <div class="column">
                <div style={{border:"1px solid #ccc",borderRadius:".4em .4em 0 0"}}>
                  <div style={{color:"#444",backgroundColor:"#ddd",padding:".1em",paddingLeft:".5em",borderRadius:".3em .3em 0 0",borderBottom:"1px solid #ccc"}}>
                      Edit Rule
                    </div>
                    <div style={{backgroundColor:"#fff",padding:".35em",paddingLeft:".5em"}}>
                      Edit rule form goes here
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      );
    }else{
      return(null);
    }
  }
}

export default Index;